.core-values-section {
    padding: 40px;
    max-width: 1200px;
    margin: 0 auto;
    text-align: center;
}

.heading {
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 20px;
}

.intro {
    font-size: 18px;
    margin-bottom: 40px;
    line-height: 1.6;
    color: #555;
}

.core-values-list {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 40px;
}

.core-value {
    flex: 1;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.value-heading {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 15px;
}

.value-description {
    font-size: 16px;
    color: #666;
}

/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
    .core-values-list {
        flex-direction: column;
    }
}
