/* General styles for the contact intro section */
.contact-intro {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

.contact-intro h1 {
    font-size: 32px;
    margin-bottom: 10px;
}

.contact-intro p {
    font-size: 18px;
    color: #555;
}

/* Styles for the reservation form */
.reservation-form {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.form-group {
    margin-bottom: 20px;
}

.form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    font-size: 16px;
}

.form-group input,
.form-group select,
.form-group textarea {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.form-group textarea {
    resize: vertical;
}

/* Submit button styles */
.submit-btn {
    width: 100%;
    padding: 10px;
    background-color: #4CAF50;
    color: white;
    font-size: 18px;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.submit-btn:hover {
    background-color: #45a049;
}

/* Media query for tablets and smaller screens (max-width: 768px) */
@media (max-width: 768px) {
    .contact-intro h1 {
        font-size: 28px;
    }

    .contact-intro p {
        font-size: 16px;
    }

    .reservation-form {
        padding: 15px;
    }

    .form-group input,
    .form-group select,
    .form-group textarea {
        font-size: 14px;
    }

    .submit-btn {
        font-size: 16px;
        padding: 10px;
    }
}

/* Media query for mobile phones (max-width: 480px) */
@media (max-width: 480px) {
    .contact-intro h1 {
        font-size: 24px;
    }

    .contact-intro p {
        font-size: 14px;
    }

    .reservation-form {
        padding: 10px;
    }

    .form-group label {
        font-size: 14px;
    }

    .form-group input,
    .form-group select,
    .form-group textarea {
        font-size: 12px;
        padding: 8px;
    }

    .submit-btn {
        font-size: 14px;
        padding: 8px;
    }
}
