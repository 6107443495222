/* .banner-slider img {
    width: 100%;
    height: auto;
  } */
  .banner-slider {
    position: relative;
  }
  
  .slide {
    position: relative;
    width: 100%;
    height: 800px; /* Adjust the height as needed */
  }
  
  .slide img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures the image covers the entire area */
  }
  
  .slide-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); /* Centers the text */
    color: white;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.5); /* Optional: Adds a semi-transparent background for readability */
    padding: 20px;
    border-radius: 10px;
  }
  
  .slide-text h1 {
    font-size: 40px;
    margin: 0;
  }
  
  .slide-text p {
    font-size: 25px;
    margin: 10px 0 0 0;
  }
  
  