/* Default styles for larger screens */
.quote-section {
  display: flex;
  align-items: center; /* Aligns items vertically */
  justify-content: center; /* Centers the content horizontally */
  text-align: center;
  padding: 50px; /* Adjust as needed for spacing */
}

.quote-icon {
  width: 70px; /* Adjust size based on your image */
  height: 70px;
  margin-right: 20px; /* Space between the image and the text */
  background: rgba(0, 0, 0, 0.1);
}

.quote-text {
  font-size: 24px; /* Adjust font size as needed */
  color: #333;
}

.quote-text .author {
  margin-top: 10px;
  font-style: italic;
  color: #777;
}

/* Media query for tablets (max-width: 768px) */
@media (max-width: 768px) {
  .quote-section {
    flex-direction: column;
    padding: 30px;
  }

  .quote-icon {
    width: 60px;
    height: 60px;
    margin-bottom: 20px; /* Add space below the icon */
  }

  .quote-text {
    font-size: 22px;
  }

  .quote-text .author {
    font-size: 18px;
  }
}

/* Media query for mobile (max-width: 480px) */
@media (max-width: 480px) {
  .quote-section {
    padding: 20px;
  }

  .quote-icon {
    width: 50px;
    height: 50px;
  }

  .quote-text {
    font-size: 20px;
  }

  .quote-text .author {
    font-size: 16px;
  }
}
