/* Styles for desktop and larger screens */
.navbar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  color: white;
  z-index: 1000;
}

.logo {
  margin-left: 20px;
  font-size: 24px;
  font-weight: bold;
}

.nav-links {
  display: flex;
  list-style: none;
  margin-right: 60px;
}

.nav-links li {
  margin-left: 20px;
}

.nav-links a {
  text-decoration: none;
  color: white;
  font-weight: bold;
}

.nav-links a:hover {
  color: #f0e68c; /* Optional hover color */
}

/* Media query for mobile screens */
@media (max-width: 768px) {
  .nav-links {
    display: none; /* Hide the links on mobile by default */
  }

  .navbar {
    padding: 10px;
  }

  .logo {
    margin-left: 10px;
    font-size: 20px;
  }

  /* Hamburger menu icon */
  .menu-icon {
    display: block;
    margin-right: 20px;
    cursor: pointer;
  }

  .menu-icon div {
    width: 25px;
    height: 3px;
    background-color: white;
    margin: 4px 0;
    transition: 0.4s;
  }

  /* Show the nav-links when the menu is clicked */
  .nav-active .nav-links {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 60px;
    right: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    padding: 10px 0;
  }

  .nav-active .nav-links li {
    margin: 10px 0;
    text-align: center;
  }
}
