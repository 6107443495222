@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';

nav ul {
  display: flex;
  gap: 10px;
  list-style: none;
}

nav ul li a {
  text-decoration: none;
  color: #007bff;
}

nav ul li a:hover {
  color: #0056b3;
}