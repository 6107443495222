/* Styles for larger screens (default) */
.banner {
  width: 100%;
  height: 500px;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  color: white;
  transition: height 0.3s ease; /* Smooth height transitions */
}

.banner-content {
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay for better text visibility */
  padding: 20px;
  border-radius: 8px;
}

.banner-title {
  font-size: 2.5em;
  font-weight: bold;
  text-align: center;
  margin: 0;
  transition: font-size 0.3s ease; /* Smooth transition for text size */
}

/* Media query for tablets (max-width: 768px) */
@media (max-width: 768px) {
  .banner {
    height: 300px; /* Adjusted height for smaller screens */
  }

  .banner-title {
    font-size: 2em;
  }

  .banner-content {
    padding: 15px;
  }
}

/* Media query for mobile phones (max-width: 480px) */
@media (max-width: 480px) {
  .banner {
    height: 200px; /* Further reduced height for mobile screens */
  }

  .banner-title {
    font-size: 1.5em; /* Adjusted font size for mobile */
  }

  .banner-content {
    padding: 10px; /* Reduced padding for smaller screens */
  }
}

/* Media query for extra-large screens (larger than 1200px) */
@media (min-width: 1200px) {
  .banner {
    height: 600px; /* Increased height for very large screens */
  }

  .banner-title {
    font-size: 3em;
  }
}
