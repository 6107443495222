.gallery-section {
    padding: 50px 20px;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .gallery-title {
    text-align: center;
    font-size: 36px;
    margin-bottom: 40px;
    font-family: 'Arial', sans-serif;
  }
  
  .gallery-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); /* Responsive grid */
    grid-gap: 20px;
  }
  
  .gallery-item {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
  }
  
  .gallery-item:hover {
    transform: scale(1.05); /* Adds a subtle zoom effect on hover */
  }
  
  .big {
    grid-column: span 2; /* Span 2 columns */
    grid-row: span 2;    /* Span 2 rows */
  }
  ul {
    list-style: none; /* Removes the bullet points */
    padding-left: 0;  /* Removes default left padding */
  }

  li {
    margin: 5px 0; /* Optional: Add some spacing between list items */
  }
  @media (max-width: 768px) {
    .big {
      grid-column: span 1; /* On smaller screens, span only 1 column */
      grid-row: span 1;
    }
  }
  