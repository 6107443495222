.footer {
    background-color: #000;
    color: #fff;
    padding: 30px 20px;
    text-align: center;
  }
  
  .footer-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }
  
  .contact-info p {
    margin: 5px 0;
  }
  
  .social-icons {
    display: flex;
    gap: 15px;
  }
  
  .social-icons a {
    color: #fff;
    font-size: 30px;
    transition: color 0.3s ease-in-out;
  }
  
  .social-icons a:hover {
    color: #007BFF; /* Change color on hover */
  }
  
  .footer-copyright p {
    margin-top: 20px;
    font-size: 14px;
  }
  
  /* Font Awesome Icons */
  .fab {
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    color:#fff;
  }
  