/* Styles for the services page */

.services-intro {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 40px;
  }
  
  .services-intro h1 {
    font-size: 36px;
    margin-bottom: 10px;
  }
  
  .services-intro p {
    font-size: 18px;
    color: #555;
  }
  
  .services-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    padding: 0 20px;
  }
  
  .service-card {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 10px;
    overflow: hidden;
    text-align: center;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s;
  }
  
  .service-card:hover {
    transform: translateY(-10px);
  }
  
  .service-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-bottom: 1px solid #ddd;
  }
  
  .service-card h3 {
    font-size: 24px;
    margin: 15px 0 10px;
  }
  
  .service-card p {
    font-size: 16px;
    color: #666;
  }
  
  /* Contact Section */
  .contact-section {
    text-align: center;
    margin: 40px 0;
    font-size: 18px;
    color: #555;
  }
  
  .contact-section a {
    color: #007bff;
    text-decoration: none;
  }
  
  .contact-section a:hover {
    text-decoration: underline;
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .services-grid {
      grid-template-columns: 1fr; /* Stack services on smaller screens */
    }
  
    .service-image {
      height: 150px; /* Adjust image size for smaller screens */
    }
  }
  