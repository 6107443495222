/* Styles for larger screens */
.text-image-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 50px;
  max-width: 1200px;
  margin: 0 auto; /* Center the content on the page */
}

.text-content {
  flex: 1; /* Takes up available space */
  padding-right: 20px;
}

.text-content h2 {
  font-size: 30px;
  margin-bottom: 15px;
}

.text-content p {
  font-size: 18px;
  line-height: 1.6;
  color: #333;
}

.image-content {
  flex: 1; /* Takes up available space */
  text-align: right;
}

.image-content img {
  max-width: 100%;
  height: auto;
  border-radius: 10px; /* Optional: Adds rounded corners to the image */
}

/* Media query for tablets (max-width: 768px) */
@media (max-width: 768px) {
  .text-image-row {
    flex-direction: column; /* Stack text and image vertically */
    text-align: center;
  }

  .text-content {
    padding-right: 0;
    margin-bottom: 20px;
  }

  .image-content img {
    width: 100%; /* Ensure image takes full width */
  }

  .text-content h2 {
    font-size: 26px;
  }

  .text-content p {
    font-size: 16px;
  }
}

/* Media query for mobile phones (max-width: 480px) */
@media (max-width: 480px) {
  .text-image-row {
    padding: 20px;
  }

  .text-content h2 {
    font-size: 22px;
  }

  .text-content p {
    font-size: 14px;
  }

  .image-content img {
    width: 100%; /* Ensure image scales properly */
  }
}
