/* Styles for larger screens */
.card-section {
  display: flex;
  justify-content: center; /* Centers the cards horizontally */
  gap: 20px; /* Adds space between the cards */
  padding: 50px; /* Adjust padding to add space around the card section */
  flex-wrap: wrap; /* Allows cards to wrap if there are many cards */
}

.card {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  width: 300px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Adds a subtle shadow for depth */
}

.card-image {
  width: 100%;
  height: 200px;
  object-fit: cover; /* Ensures the image fits nicely within the card */
}

.card-content {
  padding: 20px;
}

.card-content h3 {
  font-size: 24px;
  margin: 0 0 10px;
}

.card-content p {
  font-size: 16px;
  margin: 0 0 20px;
}

.learn-more-button {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.learn-more-button:hover {
  background-color: #0056b3; /* Changes color on hover */
}

/* Media query for tablets (max-width: 768px) */
@media (max-width: 768px) {
  .card-section {
    flex-direction: column;
    align-items: center; /* Center the cards on the page */
    padding: 30px; /* Adjust padding for smaller screens */
  }

  .card {
    width: 80%; /* Cards take up more width on smaller screens */
    margin-bottom: 20px; /* Adds space between stacked cards */
  }

  .card-content h3 {
    font-size: 22px;
  }

  .card-content p {
    font-size: 14px;
  }
}

/* Media query for mobile (max-width: 480px) */
@media (max-width: 480px) {
  .card-section {
    padding: 20px; /* Further reduce padding */
  }

  .card {
    width: 100%; /* Cards take full width on mobile */
    margin-bottom: 20px;
  }

  .card-content h3 {
    font-size: 20px;
  }

  .card-content p {
    font-size: 12px;
  }

  .learn-more-button {
    font-size: 14px;
    padding: 8px 16px;
  }
}
